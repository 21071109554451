import axios from "axios";
import API_URL from "../config";
import { TOAST_MODE } from "../constants/Common";
import { ShowToast } from "../services/Toast";
import { SOMETHING_WENT_WRONG } from "../constants/Messages";

const refreshTokenStatusCode = 401;

function refreshToken(type, URL, data, callback) {
  axios({
    url: `${API_URL}/api/v1/UserAuth/RefreshToken`,
    method: "POST",
    data: {
      accessToken: localStorage.getItem("token"),
      refreshToken: localStorage.getItem("refreshToken"),
    },
  })
    .then((res) => {
      if (res.data.isSuccess == true) {
        localStorage.setItem("token", res.data.result.accessToken);
        localStorage.setItem("refreshToken", res.data.result.refreshToken);
        if (type == "GET") GET(URL, data, callback);
        else if (type == "POST") POST(URL, data, callback);
        else if (type == "PUT") PUT(URL, data, callback);
        else if (type == "DELETE") DELETE(URL, data, callback);
      }
    })
    .catch((err) => {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      window.location.href = "/login";
    });
}

export function GET(URL, data, callback) {
  axios({
    url: URL,
    method: "GET",
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
    data: data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {      
      if (err.response.status == refreshTokenStatusCode) {
        refreshToken("GET", URL, data, callback);
      }
    });
}

export function POST(URL, data, callback) {
  axios({
    // Endpoint to send files
    url: URL,
    method: "POST",
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
    data: data,
  })
    .then((res) => {
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      if (err.response.status == refreshTokenStatusCode) {
        refreshToken("POST", URL, data, callback);
      }
    });
}

export function PUT(URL, data, callback) {
  axios({
    // Endpoint to send files
    url: URL,
    method: "PUT",
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
    data: data,
  })
    .then((res) => {
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      if (err.response.status == refreshTokenStatusCode) {
        refreshToken("PUT", URL, data, callback);
      }
    });
}

export function DELETE(URL, data, callback) {
  axios({
    // Endpoint to send files
    url: URL,
    method: "DELETE",
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
    data: data,
  })
    .then((res) => {
      if (callback) {
        callback(res.data);
      }
    })

    // Catch errors if any
    .catch((err) => {
      if (err.response.status == refreshTokenStatusCode) {
        refreshToken("DELETE", URL, data, callback);
      }
    });
}

// Displays results from a server callback
// Pass in "response.data" - not the parent response
export function DisplayResults(result, successMessage, failureMessage = null) {
  if (result.isSuccess) {
    if (successMessage != null) {
      ShowToast(TOAST_MODE.SUCCESS, successMessage);
    }

    return true;
  }
  else {
    var message = failureMessage ?? SOMETHING_WENT_WRONG;
    if (result.errorMessages != null && result.errorMessages.length > 0) {
      message = result.errorMessages[0];
    }

    ShowToast(TOAST_MODE.ERROR, message);
    return false;
  }
}