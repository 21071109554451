import React, { useCallback, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import API_URL from "../config";
import Grid from "../components/Grid";
import { DisplayResults, GET } from "../services/httpClient";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const ProductReport = () => {
  const [gridData, setGridData] = useState([]);
  const [kitchens, setKitchens] = useState([]);
  const [locations, setLocations] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const selectedKitchen = useRef();
  const selectedLocation = useRef();
  const selectedProduct = useRef();

  // useEffect = run only once
  useEffect(() => {
    fetchKitchenHandler();
    fetchProductHandler();
  }, []);

  // useCallback can be called each time state changes
  const fetchKitchenHandler = useCallback(async () => {
    try {
      GET(
        `${API_URL}` + "/api/v1/Kitchen/GetActiveKitchens",
        {},
        getKitchenDataCallback
      );
    } catch (err) {
      console.log(err);
    }
  }, []);

  //Sets the kitchen state for drodpown options
  const getKitchenDataCallback = (response) => {
    var temp = [
      {
        id: 0,
        name: "All",
      },
    ];
    if (DisplayResults(response)) {
      var data = response.result;
      data.map((d) => {
        var u = {
          id: d.id,
          name: d.name,
        };
        temp.push(u);
      });
    }

    setKitchens(temp);
    setSelectedStartDate(getFirstDateOfLastMonth());
    setSelectedEndDate(getLastDayOfLastMonth());
    kitchenChangeHandler();
  };

  //Sets the location state for drodpown options
  const getFirstDateOfLastMonth = () => {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const firstDayOfLastMonth = new Date(
      lastMonth.getFullYear(),
      lastMonth.getMonth(),
      1
    );
    return firstDayOfLastMonth;
  };

  const getLastDayOfLastMonth = () => {
    const firstDayOfThisMonth = new Date();
    const firstDayOfLastMonth = new Date(
      firstDayOfThisMonth.getFullYear(),
      firstDayOfThisMonth.getMonth() - 1,
      1
    );
    const lastDayOfLastMonth = new Date(
      firstDayOfThisMonth.getFullYear(),
      firstDayOfThisMonth.getMonth(),
      0
    );
    return lastDayOfLastMonth;
  };

  const getLocationDataCallback = (response) => {
    var temp = [
      {
        id: 0,
        name: "All"
      }
    ];

    if (DisplayResults(response)) {
      var data = response.result;
      if (data != null) {
        data.map((d) => {
          var u = {
            id: d.id,
            name: d.name,
          };
          temp.push(u);
        });
      }
    }

    setLocations(temp);
  };

  // useCallback can be called each time state changes
  const fetchProductHandler = useCallback(async () => {
    try {
      GET(
        `${API_URL}` + "/api/v1/Product/GetActiveProducts",
        {},
        getProductDataCallback
      );
    } catch (err) {
      console.log(err);
    }
  }, []);

  //Sets the product state for drodpown options
  const getProductDataCallback = (response) => {
    var temp = [
      {
        id: 0,
        name: "All",
      },
    ];

    if (DisplayResults(response)) {
      var data = response.result;
      data.map((d) => {
        var u = {
          id: d.id,
          name: d.name,
        };
        temp.push(u);
      });
    }

    setProducts(temp);
  };

  const kitchenChangeHandler = () => {
    var kitchenId = selectedKitchen.current.value;
    const suffix = kitchenId == 0 ? "" : `?kitchenId=${kitchenId}`;
    GET(
      `${API_URL}/api/v1/Location/GetActiveLocations${suffix}`,
      {},
      getLocationDataCallback
    );
  }

  const kitchenList =
    kitchens.length > 0 &&
    kitchens.map((item, i) => {
      return (
        <option
          key={i + item.id+item.name}
          value={item.id}
          data-keyid={item.id}
          selected={item.id == 0}
        >
          {item.name}
        </option>
      );
    });

  const locationList =
    locations.length > 0 &&
    locations.map((item, i) => {
      return (
        <option
          key={i + item.name+item.id}
          value={item.id}
          label={item.name}
          data-keyid={item.id}
        />
      );
    });

  const productList =
    products.length > 0 &&
    products.map((item, i) => {
      return (
        <option
          key={i + item.id+item.name}
          value={item.id}
          data-keyid={item.id}
          selected={item.id == 0}
        >
          {item.name}
        </option>
      );
    });

  const runReportHandler = () => {
    fetchReportData(
      selectedProduct.current.selectedOptions[0].attributes["data-keyid"].value,
      selectedKitchen.current.selectedOptions[0].attributes["data-keyid"].value,
      selectedLocation.current.selectedOptions[0].attributes["data-keyid"]
        .value,
      selectedStartDate.toLocaleDateString(),
      selectedEndDate.toLocaleDateString()
    );
  };

  const fetchReportData = (
    productId,
    kitchenId,
    locationId,
    startDate,
    endDate
  ) => {
    setGridData(null); // shows the spinner on the grid

    GET(
      `${API_URL}` +
        "/api/v1/Report/GetProductReport?" +
        (productId != 0 ?"productId=" + productId : "") +
        (kitchenId != 0 ? "&kitchenId=" + kitchenId : "") +
        (locationId != 0 ? "&locationId=" + locationId : "") +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate,
      {},
      getDataCallback
    );
  };

  const getDataCallback = (response) => {
    var temp = [];
    
    if (DisplayResults(response)) {
      var data = response.result;
      data.map((d) => {
        var u = {
          Product: d.product,
          Made: d.made,
          Shrink: d.shrink,
          "Shrink %": d.shrinkPercentage * 100,
          Sold: d.sold,
          "Unit Cost": d.unitCost,
          "Total Cost": d.totalCost,
          "Total Profit": d.totalProfit,
          "Profit/unit": d.profitPerUnit,
        };
        temp.push(u);
      });
    }

    setGridData(temp);
  };

  const defaultColDef = {
    sortable: true,
    filter: true,
    filterParams: {
      buttons: ["reset", "apply"],
      debounceMs: 200,
    },
  };

  const [columnDefs] = useState([
    { field: "Product", flex: 2 },
    { field: "Made", flex: 1 },
    { field: "Shrink", flex: 1 },
    {
      field: "Shrink %",
      flex: 1,
      valueFormatter: (params) => {
        return params.value.toFixed(0) + "%";
      },
    },
    { field: "Sold", flex: 1 },
    {
      field: "Unit Cost",
      flex: 1,
      valueFormatter: (params) => {
        return "$" + params.value.toFixed(2);
      },
    },
    {
      field: "Total Cost",
      flex: 1,
      valueFormatter: (params) => {
        return "$" + params.value.toFixed(2);
      },
    },
    {
      field: "Total Profit",
      flex: 1,
      valueFormatter: (params) => {
        return "$" + params.value.toFixed(2);
      },
    },
    {
      field: "Profit/unit",
      flex: 1,
      valueFormatter: (params) => {
        return "$" + params.value.toFixed(2);
      },
    },
  ]);

  return (
    <div className="root-block">
      <h2>Product Report</h2>
      <Row className="mb-3">
        <Col xs={2}>
          <Form.Label>Production Kitchen</Form.Label>
        </Col>
        <Col xs={2}>
          <Form.Select
            aria-label="Default label"
            ref={selectedKitchen}
            onChange={kitchenChangeHandler}
          >
            {kitchenList}
          </Form.Select>
        </Col>
        <Col xs={1}>
          <Form.Label>Product</Form.Label>
        </Col>
        <Col xs={2}>
          <Form.Select
            aria-label="Default label"
            ref={selectedProduct}
          >
            {productList}
          </Form.Select>
        </Col>
        <Col xs={1}>
          <Form.Label>Start Date</Form.Label>
        </Col>
        <Col xs={2}>
          {/* <Form.Control type="date" ref={startDateControl} /> */}
          <DatePicker
            selected={selectedStartDate}
            onChange={(date) => setSelectedStartDate(date)}
            dateFormat="MM/dd/yyyy"
            className="date-picker"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={2}>
          <Form.Label>Customer Location</Form.Label>
        </Col>
        <Col xs={2}>
          <Form.Select
            aria-label="Default label"
            ref={selectedLocation}
          >
            {locationList}
          </Form.Select>
        </Col>
        <Col xs={3}></Col>
        <Col xs={1}>
          <Form.Label>End Date</Form.Label>
        </Col>
        <Col xs={2}>
          {/* <Form.Control type="date" ref={endDateControl} /> */}
          <DatePicker
            selected={selectedEndDate}
            onChange={(date) => setSelectedEndDate(date)}
            dateFormat="MM/dd/yyyy"
            className="date-picker"
          />
        </Col>
      </Row>
      <Grid
        showExportButton={true}
        runFunction={runReportHandler}
        sortable={true}
        filter={true}
        data={gridData}
        columnDefs={columnDefs}
        height="64%"
      />
    </div>
  );
};

export default ProductReport;
