import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import API_URL from "../config";
import { ROLES } from "../constants/Common";
import AuthContext from "../store/auth-context";
import { DELETE, DisplayResults, GET, POST, PUT } from "../services/httpClient";
import { PASSWORD_CHANGE_SUCCESS, USER_CREATED, USER_UPDATED } from "../constants/Messages";
import { ADD_USER, DUPLICATE_USER, EDIT_USER, USER_DELETION_FAILED } from "../constants/Messages";

const UserDetails = () => {
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();
  const [heading, setHeading] = useState(ADD_USER);
  const [userInfo, setUserInfo] = useState({});
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [locationPermission, setLocationPermission] = useState(1); // 1=All Locations, 2=My Kitchen Location, 3=Specific Locations
  const [showCustomLocationGrid, setShowCustomLocationGrid] = useState(false);
  const [kitchens, setKitchens] = useState([]);
  const [locations, setlocations] = useState([]);
  const [mode, setMode] = useState(1); // 1 for create, 2 for edit
  const [validated, setValidated] = useState(false);
  const [tempLocations, setTempLocations] = useState([]);
  const [customlocations, setCustomLocations] = useState([]);
  const allCustomerLocation = useRef();
  const userEmail = useRef("");
  const userName = useRef("");
  const userRoleControl = useRef();
  const [userRoleId, setUserRoleId] = useState(1); // 1=Admin, 2=Driver, 3=Recorder, 4=Production
  const locationContainer = useRef();
  const userKitchenId = useRef(1);
  const allCustomerLocationForKitchen = useRef();
  const selectedLocations = useRef();

  //Function related to Modal pop up
  const handleClose = () => {
    setShowLocationModal(false);
    setTempLocations([]);
  };

  const handleShow = () => {
    // Admin and Production roles can't set location permission
    if (["1","4"].includes(userRoleId)) {
      return;
    }

    setShowLocationModal(true);
    setShowCustomLocationGrid(userInfo.locationPermissionId === 3);
  };

  const handleLocationSelector = () => {
    var val = 1;
    if (allCustomerLocationForKitchen.current.checked === true) {
      val = 2;
    } else if (selectedLocations.current.checked === true) {
      val = 3;
      var t = [...tempLocations];
      setCustomLocations(t);
    }
    setTempLocations([]);
    setLocationPermission(val);
    setShowLocationModal(false);
  };

  const locationClickHandler = (event) => {
    var array = [...tempLocations]; // make a separate copy of the array
    var id = event.currentTarget.id;
    if (event.currentTarget.checked === true) {
      array.push(Number(id));
    } else {
      var index = array.indexOf(Number(id));
      if (index !== -1) {
        array.splice(index, 1);
      }
    }
    setTempLocations(array);
  };

  const selectCustomLocations = (event) => {
    setShowCustomLocationGrid(selectedLocations.current.checked);
  };

  //Sets the kitchen state for drodpown options
  const getKitchenDataCallback = (response) => {
    var temp = [];

    if (DisplayResults(response)) {
      var data = response.result;
      data.forEach((d) => {
        var u = {
          id: d.id,
          name: d.name,
        };
        temp.push(u);
      });
    }

    setKitchens(temp);
    if (temp.length > 0) {
      fetchLocationHandler(temp[0].id);
    }
  };

  //Sets the location state for drodpown options
  const getLocationDataCallback = (response) => {
    var temp = [];

    if (DisplayResults(response)) {
      // Ensure some locations actually exist
      if (response.statusCode !== 204) {
        var data = response.result;
        data.forEach((d) => {
          var u = {
            id: d.id,
            name: d.name,
          };
          temp.push(u);
        });
      }
    }

    setlocations(temp);
};

  const kitchenChangeHandler = () => {
    setLocationPermission(1);
    var array = [...customlocations];
    array = [];
    setCustomLocations(array);
    fetchLocationHandler(userKitchenId.current.value);
  };

  const getUserCallBack = (response) => {
    if (DisplayResults(response)) {
      var data = response.result;
      var userInfo = {
        id: data.id,
        name: data.name,
        email: data.email,
        roleId: data.roleId,
        kitchenId: data.kitchenId,
        locationPermissionId: data.locationPermissionId,
        phone: data.phone,
        locationList: data.locationList,
      };

      userName.current.value = userInfo.name;
      userEmail.current.value = userInfo.email;

      setCustomLocations(userInfo.locationList);
      setTempLocations(userInfo.locationList);
      setLocationPermission(userInfo.locationPermissionId);

      setUserInfo(userInfo);
      setHeading(EDIT_USER);
      fetchLocationHandler(userInfo.kitchenId);
    }
  };

  const fetchUserHandler = useCallback(async (id) => {
    try {
      GET(
        `${API_URL}/api/v1/User/GetUser?userId=` + id,
        {},
        getUserCallBack
      );
    } catch (err) {
      console.log(err);
    }
  }, []);

  const changePasswordCallback = (response) => {
    DisplayResults(response, PASSWORD_CHANGE_SUCCESS);
  };

  const changePassword = () => {
    POST(
      `${API_URL}/api/v1/UserAuth/ChangePassword?email=` + userInfo.email,
      {},
      changePasswordCallback
    );
  };

  const saveUserCallback = (response) => {
    const successMessage = mode === 1 ? USER_CREATED : USER_UPDATED;
    const failureMessage = response.statusCode === 300 ? DUPLICATE_USER : null;
    if (DisplayResults(response, successMessage, failureMessage)) {
      navigate("/users");
    }
  };

  const saveUserHandler = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      setValidated(true);
    } else {
      var userData = {
        Id: mode === 1 ? 0 : userInfo.id,
        Name: userName.current.value,
        Email: userEmail.current.value,
        roleId:
          userRoleControl.current.selectedOptions[0].attributes["data-keyid"].value,
        kitchenId:
          userKitchenId.current.selectedOptions[0].attributes["data-keyid"]
            .value,
        locationPermissionId: locationPermission,
        userLocations: customlocations,
      };

      if (mode === 1) {
        POST(`${API_URL}/api/v1/User/AddUser`, userData, saveUserCallback);
      } else {
        PUT(
          `${API_URL}/api/v1/User/UpdateUser`,
          userData,
          saveUserCallback
        );
      }
    }
  };

  const fetchLocationHandler = useCallback(async (id) => {
    GET(
      `${API_URL}/api/v1/Location/GetAllLocations?kitchenId=` + id,
      {},
      getLocationDataCallback
    );
  }, []);

  const fetchKitchenHandler = useCallback(async () => {
    GET(
      `${API_URL}/api/v1/Kitchen/GetAllKitchens`,
      {},
      getKitchenDataCallback
    );
  }, []);

  const removeUserHandler = () => {
    DELETE(
      `${API_URL}/api/v1/User/RemoveUser?id=` + params.userId,
      {},
      (response) => {
        handleClose();
        
        if (DisplayResults(response, "Removed Successfully", USER_DELETION_FAILED)) {
          navigate("/users");
        }
      }
    );
  };

  useEffect(() => {
    fetchKitchenHandler();
    setMode(1);
    if (params.userId) {
      setMode(2);
      fetchUserHandler(params.userId);
    }
  }, []);

  const roleList = ROLES.map((item, i) => {
    return (
      <option
        key={item + item.id}
        value={item.id}
        data-keyid={item.id}
        selected={userInfo.roleId === item.id}
      >
        {item.name}
      </option>
    );
  });

  const kitchenList =
    kitchens.length > 0 &&
    kitchens.map((item, i) => {
      return (
        <option
          key={i + item.id}
          value={item.id}
          data-keyid={item.id}
          selected={userInfo.kitchenId === item.id}
        >
          {item.name}
        </option>
      );
    });

  const locationList =
    locations.length > 0 &&
    locations.map((item, i) => {
      return (
        <Form.Check
          key={i + item.name}
          label={item.name}
          id={item.id}
          name="group1"
          type="checkbox"
          defaultChecked={customlocations.indexOf(item.id) > -1}
          onClick={locationClickHandler}
          disabled={!showCustomLocationGrid}
        />
      );
    });

  const renderCustomerLocation = () => {
    // If the user is Admin or Production, we do not allow conditional location specification anymore
    const conditionalClass = ["1","4"].includes(userRoleId) ? "" : "link";
    
    if (locationPermission === 1) {
      return <span className={conditionalClass}>All customer locations</span>;
    } else if (locationPermission === 2) {
      return (
        <span className={conditionalClass}>
          All customer locations in the default kitchen
        </span>
      );
    } else {
      return (
        <span className={conditionalClass}>Only customer locations specified below</span>
      );
    }
  };

  return (
    <div className="root-block">
      <h2>{heading}</h2>
      <Form noValidate validated={validated} onSubmit={saveUserHandler}>
        <Row className="mb-3">
          <Col xs={3}>
            <Form.Label>Name</Form.Label>
          </Col>
          <Col xs={4}>
            <Form.Control
              type="text"
              placeholder="Enter name"
              maxLength={100}
              ref={userName}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a name.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={3}>
            <Form.Label>Email</Form.Label>
          </Col>
          <Col xs={4}>
            <Form.Control
              type="email"
              placeholder="Enter email"
              maxLength={100}
              ref={userEmail}
              required
            />

            <Form.Control.Feedback type="invalid">
              Please choose an email
            </Form.Control.Feedback>
          </Col>
          {mode === 2 && (
            <Col xs={2}>
              <Button variant="link" onClick={changePassword}>
                Reset Password
              </Button>
            </Col>
          )}
        </Row>
        <Row className="mb-3">
          <Col xs={3}>
            <Form.Label>Role</Form.Label>
          </Col>
          <Col xs={4}>
            <Form.Select aria-label="Default Role example" 
              ref={userRoleControl}
              onChange={() => {
                // If we went from Driver/Recorder to Admin/Production, reset the location permission
                const newRoleId = userRoleControl.current.selectedOptions[0].attributes["data-keyid"].value;
                if (newRoleId === 1) {
                  setLocationPermission(1);
                } else if (newRoleId === 4) {
                  setLocationPermission(2);
                }
                
                setUserRoleId(newRoleId);
              }}>
              {roleList}
            </Form.Select>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={3}>
            <Form.Label>Default Production Kitchen</Form.Label>
          </Col>
          <Col xs={4}>
            <Form.Select
              aria-label="Default label"
              ref={userKitchenId}
              onChange={kitchenChangeHandler}
            >
              {kitchenList}
            </Form.Select>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={3}>
            <Form.Label>Customer Location</Form.Label>
          </Col>
          <Col xs={4}>
            <a onClick={handleShow}>{renderCustomerLocation()}</a>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={3}>
            <Button 
              className="button-standard"
              variant="outline-danger"
              onClick={() => {setShowDeletionModal(true)}}
              disabled={!authCtx.writeRights.includes("UserList")}>
              Remove
            </Button>
          </Col>
          <Col xs={2}></Col>
          <Col xs={1} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button 
              className="button-standard"
              variant="outline-dark">
              <Link to="/users">Close</Link>
            </Button>
          </Col>

          <Col xs={1} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button 
              className="button-standard"
              variant="primary" 
              type="submit"
              disabled={!authCtx.writeRights.includes("UserList")}>
              Save
            </Button>
          </Col>
        </Row>
      </Form>

      <Modal show={showLocationModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Customer Location Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Check
                type="radio"
                id="1"
                name="location-selector"
                label="All customer locations"
                ref={allCustomerLocation}
                onClick={selectCustomLocations}
                defaultChecked={locationPermission === 1}
              />
              <Form.Check
                type="radio"
                id="2"
                name="location-selector"
                label="All customer locations in the default kitchen"
                ref={allCustomerLocationForKitchen}
                onClick={selectCustomLocations}
                defaultChecked={locationPermission === 2}
              />

              <Form.Check
                id="3"
                type="radio"
                name="location-selector"
                label="Only customer locations specified below"
                ref={selectedLocations}
                onClick={selectCustomLocations}
                defaultChecked={locationPermission === 3}
              />

              <div className="location-container" ref={locationContainer}>
                {locationList}
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleLocationSelector}>
            Select
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeletionModal} onHide={() => {setShowDeletionModal(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you wish to delete the user?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={() => {setShowDeletionModal(false)}}>
            NO
          </Button>
          <Button variant="danger" onClick={removeUserHandler}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserDetails;
